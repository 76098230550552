import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import { PAYOUT_INVOICE_SCHEDULE_TYPE } from 'models/Invoice/Payout';

export const PeriodicitySelect: TComponent = () => {
    const { t } = useTranslation();

    return (
        <Form.Item label={t('common.form.periodicity')} name='scheduleType'>
            <Select placeholder={t('common.form.choosePeriodicity')}>
                <Select.Option value={PAYOUT_INVOICE_SCHEDULE_TYPE.DAILY}>
                    {t('invoices.payout.exportSettings.schedulingType.daily')}
                </Select.Option>
                <Select.Option value={PAYOUT_INVOICE_SCHEDULE_TYPE.WEEKLY}>
                    {t('invoices.payout.exportSettings.schedulingType.weekly')}
                </Select.Option>
                <Select.Option value={PAYOUT_INVOICE_SCHEDULE_TYPE.EVEN_WEEKS}>
                    {t(
                        'invoices.payout.exportSettings.schedulingType.evenWeeks',
                    )}
                </Select.Option>
                <Select.Option value={PAYOUT_INVOICE_SCHEDULE_TYPE.ODD_WEEKS}>
                    {t(
                        'invoices.payout.exportSettings.schedulingType.oddWeeks',
                    )}
                </Select.Option>
                <Select.Option value={PAYOUT_INVOICE_SCHEDULE_TYPE.MONTHLY}>
                    {t('invoices.payout.exportSettings.schedulingType.monthly')}
                </Select.Option>
            </Select>
        </Form.Item>
    );
};
