import { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import { InvoicesExportSettingsModal } from '../InvoicesExportSettingsModal';
import styles from './InvoicesExportSettingsModalButton.module.scss';

export const InvoicesExportSettingsModalButton: TComponent = () => {
    const { t } = useTranslation();

    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleClick = () => {
        setIsModalOpened(true);
    };

    const handleClose = () => {
        setIsModalOpened(false);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                className={styles.exportSettingsButton}
            >
                <Tooltip title={t('common.autoExport')}>
                    {t('common.buttons.autoExport')}
                </Tooltip>
            </Button>

            {isModalOpened && (
                <InvoicesExportSettingsModal onClose={handleClose} />
            )}
        </>
    );
};
