import { payoutInvoicesTableFiltersSelectors } from 'store/invoices/payout/payoutInvoicesTableFiltersSelectors';
import { payoutInvoicesTableSelectors } from 'store/invoices/payout/payoutInvoicesTableSelectors';
import { usePayoutInvoiceTable } from 'store/invoices/payout/usePayoutInvoiceTable';
import { usePayoutInvoiceTableFilters } from 'store/invoices/payout/usePayoutInvoiceTableFilters';
import Table from 'ui/Table/Table';
import type { TComponent } from 'utils/types/component';

import { useOnce } from 'components/utils/hooks/useOnce';

import { CustomPagination } from './components/Paginations';
import { getColumns } from './columns';
import styles from './EditSettingsTable.module.scss';

export const EditSettingsTable: TComponent = () => {
    const getPayoutReports = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.getPayoutReports,
    );
    const updatePaymentReportEnabled = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.updatePayoutReportEnabled,
    );
    const deletePaymentReport = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.deletePayoutReport,
    );
    const payoutReports = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.payoutReports,
    );
    const isLoading = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.isReportsLoading,
    );
    const params = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getReportsParams,
    );

    useOnce(
        () => true,
        () => {
            getPayoutReports(params);
        },
        [],
    );

    const handleToggleEnabled = (identity: string, checked: boolean) => {
        updatePaymentReportEnabled(identity, checked, params);
    };

    const handleDeleteReport = (identity: string) => {
        deletePaymentReport(identity, params);
    };

    const tableColumns = getColumns(handleToggleEnabled, handleDeleteReport);

    return (
        <div className={styles.tableContainer}>
            <Table
                loading={isLoading}
                tableRows={payoutReports}
                columns={tableColumns}
                onChange={() => {}}
                tableLayout='fixed'
            />
            <div className={styles.tableBottom}>
                <CustomPagination />
            </div>
        </div>
    );
};
