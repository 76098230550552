import { notification } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { paymentInvoicesTableFiltersSelectors } from 'store/invoices/payment/paymentInvoicesTableFiltersSelectors';
import { paymentInvoicesTableSelectors } from 'store/invoices/payment/paymentInvoicesTableSelectors';
import { usePaymentInvoiceTable } from 'store/invoices/payment/usePaymentInvoiceTable';
import { usePaymentInvoiceTableFilters } from 'store/invoices/payment/usePaymentInvoiceTableFilters';
import Loader from 'ui/Loader';
import Table from 'ui/Table';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import { getSortParams } from 'utils/helpers/tableHelpers';
import type { TComponent } from 'utils/types/component';

import { useOnce } from 'components/utils/hooks/useOnce';
import { usePageVisibility } from 'components/utils/hooks/usePageVisibility';

import type { IPaymentInvoice } from 'models/Invoice/Payment';
import type { TTableSort } from 'models/Table';

import { Filters } from './components/Filters';
import { CustomPagination } from './components/Paginations';
import { COLUMNS } from './columns';
import styles from './PaymentInvoicesTable.module.scss';

export const PaymentInvoicesTable: TComponent = () => {
    const { t } = useTranslation();

    const data = usePaymentInvoiceTable(paymentInvoicesTableSelectors.rows);
    const loading = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.isLoading,
    );
    const getTable = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.getTable,
    );
    const exportPaymentInvoices = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.exportPaymentInvoices,
    );

    const body = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getBody,
    );
    const params = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getParams,
    );
    const getCurrenciesOptions = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getCurrenciesOptions,
    );
    const getPaymentMethodNamesOptions = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getPaymentMethodNamesOptions,
    );
    const getSitesOptions = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getSitesOptions,
    );

    const handleExport = async (email: string) => {
        const startDate = body.dateFilter?.createdAtFrom;
        const endDate = body.dateFilter?.createdAtTo;
        const finishedAtFromDate = body.finishedDateFilter?.finishedAtFrom;
        const finishedAtToDate = body.finishedDateFilter?.finishedAtTo;

        if (body.dateFilter && (!startDate || !endDate)) {
            return notification.error({
                message: t('invoices.payment.export.filterOutOfDateError'),
            });
        }

        if (
            body.finishedDateFilter &&
            (!finishedAtFromDate || !finishedAtToDate)
        ) {
            return notification.error({
                message: t('invoices.payment.export.filterOutOfDateError'),
            });
        }

        if (
            !(startDate && endDate) &&
            !(finishedAtFromDate && finishedAtToDate)
        ) {
            return notification.error({
                message: t('invoices.payment.export.filterOutOfDateError'),
            });
        }

        if (
            startDate &&
            endDate &&
            !dateHelpers.isWithin14Days(startDate, endDate)
        ) {
            return notification.error({
                message: t(
                    'invoices.payment.export.filterWrongDatePeriodError',
                ),
            });
        }

        if (
            finishedAtFromDate &&
            finishedAtToDate &&
            !dateHelpers.isWithin14Days(finishedAtFromDate, finishedAtToDate)
        ) {
            return notification.error({
                message: t(
                    'invoices.payment.export.filterWrongDatePeriodError',
                ),
            });
        }

        await exportPaymentInvoices({
            filter: body,
            email,
        });

        notification.success({
            message: t('invoices.payment.export.exportSuccess'),
        });
    };

    const handleSortTable = (
        _: number,
        sortParams: TTableSort<IPaymentInvoice>,
    ) => {
        const { property, order } = getSortParams(sortParams);

        const newParams = { ...params, sort: `${property},${order}` };

        getTable(body, newParams);
    };

    useOnce(
        () => true,
        () => {
            getTable(body, params);
            getCurrenciesOptions();
            getPaymentMethodNamesOptions();
            getSitesOptions();
        },
        [],
    );

    usePageVisibility(() => {
        getTable(body, params);
        getCurrenciesOptions();
    });

    return (
        <Loader active={loading}>
            <div className={styles.container}>
                <div className={cn(styles.actionBar, 'actionBar')}>
                    <Filters onExport={handleExport} />
                </div>
                <Table
                    scroll={{ x: 'max-content' }}
                    columns={COLUMNS}
                    tableRows={data}
                    onChange={handleSortTable}
                    isResizable
                />
                <div className={styles.tableBottom}>
                    <CustomPagination />
                </div>
            </div>
        </Loader>
    );
};
