import type {
    TPaymentPaymentMethod,
    TPayoutPaymentMethod,
} from 'api/merchants/balance/types';

import type { TTableColumn } from 'models/Table';

const depositColumns: TTableColumn<TPaymentPaymentMethod>[] = [
    {
        title: 'balance.detailsModal.name',
        dataIndex: 'formViewName',
        key: 'paymentMethodName',
    },
    {
        title: 'balance.detailsModal.paymentCommissionPercent',
        dataIndex: 'paymentCommissionPercent',
        key: 'paymentCommissionPercent',
        sorter: true,
    },
    {
        title: 'balance.detailsModal.paymentCommissionFixed',
        dataIndex: 'paymentCommissionFixed',
        key: 'paymentCommissionFixed',
        sorter: true,
    },
    {
        title: 'balance.detailsModal.todayPaymentAmount',
        dataIndex: 'todayPaymentAmount',
        key: 'todayPaymentAmount',
        sorter: true,
    },
    {
        title: 'balance.detailsModal.todayPaymentCommissionAmount',
        dataIndex: 'todayPaymentCommissionAmount',
        key: 'todayPaymentCommissionAmount',
        sorter: true,
    },
];

const payoutsColumns: TTableColumn<TPayoutPaymentMethod>[] = [
    {
        title: 'balance.detailsModal.name',
        dataIndex: 'formViewName',
        key: 'payoutMethodName',
    },
    {
        title: 'balance.detailsModal.payoutCommissionPercent',
        dataIndex: 'payoutCommissionPercent',
        key: 'payoutCommissionPercent',
        sorter: true,
    },
    {
        title: 'balance.detailsModal.payoutCommissionFixed',
        dataIndex: 'payoutCommissionFixed',
        key: 'payoutCommissionFixed',
        sorter: true,
    },
    {
        title: 'balance.detailsModal.todayPayoutAmount',
        dataIndex: 'todayPayoutAmount',
        key: 'todayPayoutAmount',
        sorter: true,
    },
    {
        title: 'balance.detailsModal.todayPayoutCommissionAmount',
        dataIndex: 'todayPayoutCommissionAmount',
        key: 'todayPayoutCommissionAmount',
        sorter: true,
    },
];

export const columns = {
    depositColumns,
    payoutsColumns,
};
