import type {
    TExportPayoutInvoicesBody,
    TExportSettingsScheduling,
    TPayoutInvoiceFindBody,
} from 'api/invoices/payout/types';
import type { Dayjs } from 'utils/helpers/dateHelpers';

import type { PAYOUT_INVOICE_SCHEDULE_TYPE } from 'models/Invoice/Payout';

import type { IForm, IFormMonthlyDay, IFormWeeklyDay } from '../types';

const DEFAULT_TIME = '00:00';
const DEFAULT_TIME_FORMAT = 'HH:mm';

const createSettingsPayload = (
    values: IForm,
    scheduleTypeValue: PAYOUT_INVOICE_SCHEDULE_TYPE,
    filters: TPayoutInvoiceFindBody,
): TExportPayoutInvoicesBody => {
    const { email } = values;

    const bodyForBackend: TExportPayoutInvoicesBody = {
        email,
        filter: filters,
        scheduling: {} as TExportSettingsScheduling,
    };

    switch (scheduleTypeValue) {
        case 'daily': {
            const time: Dayjs = values.dailyTime;
            bodyForBackend.scheduling = {
                type: scheduleTypeValue,
                enabled: true,
                executionTime: time
                    ? time.format(DEFAULT_TIME_FORMAT)
                    : DEFAULT_TIME,
            };
            break;
        }

        case 'weekly':
        case 'evenWeeks':

        case 'oddWeeks': {
            const weekDays = (values.weekDays || []).map(
                (item: IFormWeeklyDay) => ({
                    dayOfWeek: item.dayOfWeek,
                    executionTime: item.executionTime
                        ? item.executionTime.format(DEFAULT_TIME_FORMAT)
                        : DEFAULT_TIME,
                }),
            );
            bodyForBackend.scheduling = {
                type: scheduleTypeValue,
                enabled: true,
                weekDays,
            };
            break;
        }

        case 'monthly': {
            const monthDays = (values.monthDays || []).map(
                (item: IFormMonthlyDay) => ({
                    dayNumber: item.dayNumber,
                    executionTime: item.executionTime
                        ? item.executionTime.format(DEFAULT_TIME_FORMAT)
                        : DEFAULT_TIME,
                }),
            );
            bodyForBackend.scheduling = {
                type: scheduleTypeValue,
                enabled: true,
                monthDays,
            };
            break;
        }
    }

    return bodyForBackend;
};

export const createSettingsFormHelpers = {
    createSettingsPayload,
};
