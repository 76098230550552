import { Button, Card, Col, Form, Input, Row, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { regexConstants } from 'utils/constants/regex';
import type { TComponent } from 'utils/types/component';

import styles from '../../CreateSettingsForm.module.scss';

export const MonthlyList: TComponent = () => {
    const { t } = useTranslation();

    return (
        <Form.List
            name='monthDays'
            rules={[
                {
                    validator: async (_, weekDays) => {
                        if (!weekDays || weekDays.length === 0) {
                            return Promise.reject(
                                new Error(
                                    t('common.form_error.monthDays_required'),
                                ),
                            );
                        }
                    },
                },
            ]}
        >
            {(fields, { add, remove }, { errors }) => (
                <>
                    <Card
                        title={t('common.form.monthDays')}
                        size='small'
                        className={styles.monthDaysCard}
                        extra={
                            <Button type='dashed' onClick={add}>
                                {t('common.form.addMonthDay')}
                            </Button>
                        }
                    >
                        {fields.map((field) => (
                            <Row
                                gutter={16}
                                key={field.key}
                                align='middle'
                                className={styles.monthDaysRow}
                            >
                                <Col span={11}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'dayNumber']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'common.form.chooseMonthDay',
                                                ),
                                            },
                                            {
                                                pattern:
                                                    regexConstants.MONTH_DAY,
                                                message: t(
                                                    'common.form_error.monthDay_wrong',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input placeholder='1' />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'executionTime']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'common.form.chooseTime',
                                                ),
                                            },
                                        ]}
                                    >
                                        <TimePicker
                                            format='HH:mm'
                                            className={styles.input}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        danger
                                        onClick={() => remove(field.name)}
                                    >
                                        {t('common.buttons.remove')}
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                    </Card>
                    {errors && errors.length > 0 && (
                        <Form.Item>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    )}
                </>
            )}
        </Form.List>
    );
};
