import { Tooltip } from 'antd';
import type { IPaymentReport } from 'api/invoices/payment/types';
import { INVOICES_CONSTANTS } from 'utils/constants/invoices';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import type { TComponent } from 'utils/types/component';

interface IFiltersCellProps {
    filters: IPaymentReport['filters'];
}

export const FiltersCell: TComponent<IFiltersCellProps> = (props) => {
    const { filters } = props;

    if (!filters) {
        return <>-</>;
    }

    const parts: string[] = [];

    if (filters.searchStr) {
        parts.push(filters.searchStr);
    }

    if (filters.externalUserId) {
        parts.push(filters.externalUserId);
    }

    if (filters.externalIdentity) {
        parts.push(filters.externalIdentity);
    }

    if (filters.currencyCode) {
        parts.push(filters.currencyCode);
    }

    if (filters.siteIdentities?.length) {
        parts.push(filters.siteIdentities.join(', '));
    }

    if (filters.methodCodes?.length) {
        parts.push(filters.methodCodes.join(', '));
    }

    if (filters.dateFilter?.createdAtFrom && filters.dateFilter?.createdAtTo) {
        const dateFromStr = dateHelpers.formatToLocalTimezone(
            filters.dateFilter.createdAtFrom,
            INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
        );
        const dateToStr = dateHelpers.formatToLocalTimezone(
            filters.dateFilter.createdAtTo,
            INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
        );
        parts.push(`${dateFromStr} - ${dateToStr}`);
    }

    if (
        filters.finishedDateFilter?.finishedAtFrom &&
        filters.finishedDateFilter?.finishedAtTo
    ) {
        const finishedFromStr = dateHelpers.formatToLocalTimezone(
            filters.finishedDateFilter.finishedAtFrom,
            INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
        );
        const finishedToStr = dateHelpers.formatToLocalTimezone(
            filters.finishedDateFilter.finishedAtTo,
            INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
        );
        parts.push(`${finishedFromStr} - ${finishedToStr}`);
    }

    if (filters.amountFilter) {
        const from = filters.amountFilter.amountFrom || '';
        const to = filters.amountFilter.amountTo || '';

        if (from || to) {
            parts.push(`${from} - ${to}`);
        }
    }

    if (filters.statuses?.length) {
        parts.push(filters.statuses.join(', '));
    }

    const joinedString = parts.join(' ').trim();

    if (!joinedString) {
        return <>-</>;
    }

    return (
        <Tooltip title={joinedString}>
            <span className='textEllipsis'>{joinedString}</span>
        </Tooltip>
    );
};
