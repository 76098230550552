import type { IPayoutReport } from 'api/invoices/payout/types';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import { editSettingsTableHelpers } from '../../helpers';

interface IActionsCellProps {
    scheduling: IPayoutReport['scheduling'];
}

export const SchedulingTypeCell: TComponent<IActionsCellProps> = (props) => {
    const { scheduling } = props;

    const { t } = useTranslation();

    return (
        <>
            {scheduling
                ? editSettingsTableHelpers.mapScheduleType(scheduling.type, t)
                : ''}
        </>
    );
};
