import { Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import { CreateSettingsForm } from './components/CreateSettingsForm';
import { EditSettingsTable } from './components/EditSettingsTable';

interface IInvoicesExportSettingsModalProps {
    onClose: () => void;
}

export const InvoicesExportSettingsModal: TComponent<
    IInvoicesExportSettingsModalProps
> = (props) => {
    const { onClose } = props;

    const { t } = useTranslation();

    return (
        <Modal
            open
            onCancel={onClose}
            footer={null}
            title={t('invoices.payment.exportSettings.title')}
            width={1000}
        >
            <Tabs>
                <Tabs.TabPane
                    tab={t('invoices.payment.exportSettings.create')}
                    key='1'
                >
                    <CreateSettingsForm onClose={onClose} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={t('invoices.payment.exportSettings.edit')}
                    key='2'
                >
                    <EditSettingsTable />
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
};
