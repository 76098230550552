import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import styles from './ExportButton.module.scss';

interface IExportButtonProps {
    onClick: () => void;
}

export const ExportButton: TComponent<IExportButtonProps> = (props) => {
    const { onClick } = props;

    const { t } = useTranslation();

    return (
        <Button onClick={onClick} className={styles.exportButton}>
            <Tooltip title={t('common.export')}>
                {t('common.buttons.export')}
            </Tooltip>
        </Button>
    );
};
