import { Button, Form, Input, notification, TimePicker } from 'antd';
import type { TExportPaymentInvoicesBody } from 'api/invoices/payment/types';
import { useTranslation } from 'react-i18next';
import { paymentInvoicesTableFiltersSelectors } from 'store/invoices/payment/paymentInvoicesTableFiltersSelectors';
import { paymentInvoicesTableSelectors } from 'store/invoices/payment/paymentInvoicesTableSelectors';
import { usePaymentInvoiceTable } from 'store/invoices/payment/usePaymentInvoiceTable';
import { usePaymentInvoiceTableFilters } from 'store/invoices/payment/usePaymentInvoiceTableFilters';
import type { TComponent } from 'utils/types/component';

import { PAYMENT_INVOICE_SCHEDULE_TYPE } from 'models/Invoice/Payment';

import { MonthlyList } from './components/MonthlyList';
import { PeriodicitySelect } from './components/PeriodicitySelect';
import { WeekList } from './components/WeekList';
import styles from './CreateSettingsForm.module.scss';
import { createSettingsFormHelpers } from './helpers';
import type { IForm } from './types';

interface ICreateSettingsFormProps {
    onClose: () => void;
}

export const CreateSettingsForm: TComponent<ICreateSettingsFormProps> = (
    props,
) => {
    const { onClose } = props;

    const exportPaymentInvoices = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.exportPaymentInvoices,
    );
    const filters = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getBody,
    );

    const { t } = useTranslation();

    const [form] = Form.useForm();

    const scheduleTypeValue = Form.useWatch<PAYMENT_INVOICE_SCHEDULE_TYPE>(
        'scheduleType',
        form,
    );

    const isDailyScheduleType =
        scheduleTypeValue === PAYMENT_INVOICE_SCHEDULE_TYPE.DAILY;
    const isWeekScheduleType =
        scheduleTypeValue === PAYMENT_INVOICE_SCHEDULE_TYPE.WEEKLY ||
        scheduleTypeValue === PAYMENT_INVOICE_SCHEDULE_TYPE.EVEN_WEEKS ||
        scheduleTypeValue === PAYMENT_INVOICE_SCHEDULE_TYPE.ODD_WEEKS;
    const isMonthlyScheduleType =
        scheduleTypeValue === PAYMENT_INVOICE_SCHEDULE_TYPE.MONTHLY;

    const handleFinish = async (values: IForm) => {
        const bodyForBackend: TExportPaymentInvoicesBody =
            createSettingsFormHelpers.createSettingsPayload(
                values,
                scheduleTypeValue,
                filters,
            );

        try {
            await exportPaymentInvoices(bodyForBackend);

            onClose();
        } catch {
            notification.error({
                message: t(
                    'invoices.payment.createExportSettingsTable.hasActiveLimitExceeded',
                ),
            });
        }
    };

    return (
        <Form
            form={form}
            layout='vertical'
            className={styles.form}
            onFinish={handleFinish}
            initialValues={{
                dateFrom: null,
                dateTo: null,
            }}
        >
            <Form.Item
                name='email'
                label={t('common.form.enterEmail')}
                rules={[
                    {
                        required: true,
                        message: t('common.form_error.email_required'),
                    },
                    {
                        type: 'email',
                        message: t('common.form_error.email_wrong'),
                    },
                ]}
            >
                <Input placeholder='example@test.com' />
            </Form.Item>

            <PeriodicitySelect />

            {isDailyScheduleType && (
                <Form.Item
                    label={t('common.form.timeToSend')}
                    name='dailyTime'
                    rules={[
                        {
                            required: true,
                            message: t('common.form.chooseTimeToSend'),
                        },
                    ]}
                >
                    <TimePicker format='HH:mm' className={styles.input} />
                </Form.Item>
            )}

            {isWeekScheduleType && <WeekList />}

            {isMonthlyScheduleType && <MonthlyList />}

            <Form.Item className={styles.sendBtnContainer}>
                <Button
                    className={styles.sendBtn}
                    htmlType='submit'
                    type='primary'
                >
                    {t('common.buttons.send')}
                </Button>
            </Form.Item>
        </Form>
    );
};
