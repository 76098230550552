import { Pagination } from 'antd';
import { payoutInvoicesTableFiltersSelectors } from 'store/invoices/payout/payoutInvoicesTableFiltersSelectors';
import { payoutInvoicesTableSelectors } from 'store/invoices/payout/payoutInvoicesTableSelectors';
import { usePayoutInvoiceTable } from 'store/invoices/payout/usePayoutInvoiceTable';
import { usePayoutInvoiceTableFilters } from 'store/invoices/payout/usePayoutInvoiceTableFilters';
import type { TComponent } from 'utils/types/component';

export const CustomPagination: TComponent = () => {
    const params = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getReportsParams,
    );
    const totalElements = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.totalReportsElements,
    );

    const setParams = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.setReportsParams,
    );
    const getPayoutReports = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.getPayoutReports,
    );

    const page = params.page + 1;

    const handleChangePage = (currentPage: number, pageSize: number) => {
        const enhancePage = currentPage - 1;

        const newParams = { ...params, page: enhancePage, size: pageSize };

        setParams(newParams);
        getPayoutReports(newParams);
    };

    return (
        <Pagination
            showSizeChanger
            current={page}
            total={totalElements}
            onChange={handleChangePage}
        />
    );
};
