import type { TFunction } from 'i18next';

import type {
    PAYMENT_INVOICE_SCHEDULE_TYPE,
    PAYMENT_INVOICE_WEEK_DAY,
} from 'models/Invoice/Payment';
import { REPORT_TYPE } from 'models/Invoice/Payment';

const mapScheduleType = (type: PAYMENT_INVOICE_SCHEDULE_TYPE, t: TFunction) => {
    switch (type) {
        case 'weekly':
            return t('invoices.payment.exportSettings.schedulingType.weekly');
        case 'evenWeeks':
            return t(
                'invoices.payment.exportSettings.schedulingType.evenWeeks',
            );
        case 'oddWeeks':
            return t('invoices.payment.exportSettings.schedulingType.oddWeeks');
        case 'daily':
            return t('invoices.payment.exportSettings.schedulingType.daily');
        case 'monthly':
            return t('invoices.payment.exportSettings.schedulingType.monthly');
        default:
            return type;
    }
};

const mapDayOfWeek = (dayOfWeek: PAYMENT_INVOICE_WEEK_DAY, t: TFunction) => {
    switch (dayOfWeek) {
        case 'MONDAY':
            return t('common.week.monday');
        case 'TUESDAY':
            return t('common.week.tuesday');
        case 'WEDNESDAY':
            return t('common.week.wednesday');
        case 'THURSDAY':
            return t('common.week.thursday');
        case 'FRIDAY':
            return t('common.week.friday');
        case 'SATURDAY':
            return t('common.week.saturday');
        case 'SUNDAY':
            return t('common.week.sunday');
        default:
            return dayOfWeek;
    }
};

const mapReportType = (type: REPORT_TYPE, t: TFunction) => {
    switch (type) {
        case REPORT_TYPE.PAYMENTS:
            return t('invoices.payment.exportSettings.reportType.payments');
        case REPORT_TYPE.PAYOUTS:
            return t('invoices.payment.exportSettings.reportType.payouts');
        default:
            return type;
    }
};

export const editSettingsTableHelpers = {
    mapScheduleType,
    mapDayOfWeek,
    mapReportType,
};
