import type { IPayoutReport } from 'api/invoices/payout/types';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import { editSettingsTableHelpers } from '../../helpers';

interface IReportTypeCellProps {
    type: IPayoutReport['type'];
}

export const ReportTypeCell: TComponent<IReportTypeCellProps> = (props) => {
    const { type } = props;

    const { t } = useTranslation();

    if (type) {
        return <>{editSettingsTableHelpers.mapReportType(type, t)}</>;
    }

    return null;
};
