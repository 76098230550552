import { Button, Card, Col, Form, Row, Select, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import { PAYMENT_INVOICE_WEEK_DAY } from 'models/Invoice/Payment';

import styles from '../../CreateSettingsForm.module.scss';

export const WeekList: TComponent = () => {
    const { t } = useTranslation();

    const WEEK_DAYS = [
        {
            label: t('common.week.monday'),
            value: PAYMENT_INVOICE_WEEK_DAY.MONDAY,
        },
        {
            label: t('common.week.tuesday'),
            value: PAYMENT_INVOICE_WEEK_DAY.TUESDAY,
        },
        {
            label: t('common.week.wednesday'),
            value: PAYMENT_INVOICE_WEEK_DAY.WEDNESDAY,
        },
        {
            label: t('common.week.thursday'),
            value: PAYMENT_INVOICE_WEEK_DAY.THURSDAY,
        },
        {
            label: t('common.week.friday'),
            value: PAYMENT_INVOICE_WEEK_DAY.FRIDAY,
        },
        {
            label: t('common.week.saturday'),
            value: PAYMENT_INVOICE_WEEK_DAY.SATURDAY,
        },
        {
            label: t('common.week.sunday'),
            value: PAYMENT_INVOICE_WEEK_DAY.SUNDAY,
        },
    ];

    return (
        <Form.List
            name='weekDays'
            rules={[
                {
                    validator: async (_, weekDays) => {
                        if (!weekDays || weekDays.length === 0) {
                            return Promise.reject(
                                new Error(
                                    t('common.form_error.weekDays_required'),
                                ),
                            );
                        }
                    },
                },
            ]}
        >
            {(fields, { add, remove }, { errors }) => (
                <>
                    <Card
                        title={t('common.form.weekDays')}
                        size='small'
                        className={styles.weekDaysCard}
                        extra={
                            <Button type='dashed' onClick={add}>
                                {t('common.form.addDay')}
                            </Button>
                        }
                    >
                        {fields.map((field) => (
                            <Row
                                gutter={16}
                                key={field.key}
                                align='middle'
                                className={styles.weekDaysRow}
                            >
                                <Col span={11}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'dayOfWeek']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'common.form.chooseWeekDay',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder={t(
                                                'common.form.weekDay',
                                            )}
                                        >
                                            {WEEK_DAYS.map((wd) => (
                                                <Select.Option
                                                    key={wd.value}
                                                    value={wd.value}
                                                >
                                                    {wd.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'executionTime']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'common.form.chooseTime',
                                                ),
                                            },
                                        ]}
                                    >
                                        <TimePicker
                                            format='HH:mm'
                                            className={styles.input}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        danger
                                        onClick={() => remove(field.name)}
                                    >
                                        {t('common.buttons.remove')}
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                    </Card>
                    {errors && errors.length > 0 && (
                        <Form.Item>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    )}
                </>
            )}
        </Form.List>
    );
};
