import { Button, Popconfirm } from 'antd';
import type { IPaymentReport } from 'api/invoices/payment/types';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

interface IActionsCellProps {
    identity: string;
    scheduling: IPaymentReport['scheduling'];
    onDeleteReport: (identity: string) => void;
}

export const ActionsCell: TComponent<IActionsCellProps> = (props) => {
    const { identity, onDeleteReport, scheduling } = props;

    const { t } = useTranslation();

    const isActive = scheduling?.enabled;

    return (
        <Popconfirm
            title={t('invoices.payment.editExportSettingsTable.removeQuestion')}
            onConfirm={() => onDeleteReport(identity)}
            okText={t('common.buttons.ok')}
            cancelText={t('common.buttons.cancel')}
            disabled={isActive}
        >
            <Button
                type='text'
                danger
                disabled={isActive}
                icon={<DeleteIcon />}
            />
        </Popconfirm>
    );
};
