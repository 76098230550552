import { paymentInvoicesTableFiltersSelectors } from 'store/invoices/payment/paymentInvoicesTableFiltersSelectors';
import { paymentInvoicesTableSelectors } from 'store/invoices/payment/paymentInvoicesTableSelectors';
import { usePaymentInvoiceTable } from 'store/invoices/payment/usePaymentInvoiceTable';
import { usePaymentInvoiceTableFilters } from 'store/invoices/payment/usePaymentInvoiceTableFilters';
import Table from 'ui/Table/Table';
import type { TComponent } from 'utils/types/component';

import { useOnce } from 'components/utils/hooks/useOnce';

import { CustomPagination } from './components/Paginations';
import { getColumns } from './columns';
import styles from './EditSettingsTable.module.scss';

export const EditSettingsTable: TComponent = () => {
    const getPaymentReports = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.getPaymentReports,
    );
    const updatePaymentReportEnabled = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.updatePaymentReportEnabled,
    );
    const deletePaymentReport = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.deletePaymentReport,
    );
    const paymentReports = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.paymentReports,
    );
    const isLoading = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.isReportsLoading,
    );
    const params = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getReportsParams,
    );

    useOnce(
        () => true,
        () => {
            getPaymentReports(params);
        },
        [],
    );

    const handleToggleEnabled = (identity: string, checked: boolean) => {
        updatePaymentReportEnabled(identity, checked, params);
    };

    const handleDeleteReport = (identity: string) => {
        deletePaymentReport(identity, params);
    };

    const tableColumns = getColumns(handleToggleEnabled, handleDeleteReport);

    return (
        <div className={styles.tableContainer}>
            <Table
                loading={isLoading}
                tableRows={paymentReports}
                columns={tableColumns}
                onChange={() => {}}
                tableLayout='fixed'
            />
            <div className={styles.tableBottom}>
                <CustomPagination />
            </div>
        </div>
    );
};
