import type { TPaymentInvoiceFindBody } from 'api/invoices/payment/types';
import { INVOICES_CONSTANTS } from 'utils/constants/invoices';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import type { IFilterForm } from '../types';

const getFiltersData = (data: IFilterForm) => {
    if (!Object.keys(data).length) {
        return;
    }

    const result: TPaymentInvoiceFindBody = {};

    if (data.searchStr) {
        result.searchStr = data.searchStr;
    }

    if (data.externalUserId) {
        result.externalUserId = data.externalUserId;
    }

    if (data.externalIdentity) {
        result.externalIdentity = data.externalIdentity;
    }

    if (data.currencyCode) {
        result.currencyCode = data.currencyCode;
    }

    if (data.methodCodes) {
        result.methodCodes = data.methodCodes;
    }

    if (data.siteIdentities) {
        result.siteIdentities = data.siteIdentities;
    }

    if (data.statuses) {
        result.statuses = data.statuses;
    }

    if (data.createdAtFrom || data.createdAtTo) {
        if (!result.dateFilter) {
            result.dateFilter = { createdAtFrom: '', createdAtTo: '' };
        }

        if (data.createdAtFrom) {
            result.dateFilter.createdAtFrom = dateHelpers.formatTo(
                data.createdAtFrom,
                INVOICES_CONSTANTS.FILTER_DATE_FORMAT,
                'UTC',
            );
        }

        if (data.createdAtTo) {
            result.dateFilter.createdAtTo = dateHelpers.formatTo(
                data.createdAtTo,
                INVOICES_CONSTANTS.FILTER_DATE_FORMAT,
                'UTC',
            );
        }
    }

    if (data.amountFrom || data.amountTo) {
        if (!result.amountFilter) {
            result.amountFilter = {};
        }

        if (data.amountFrom) {
            result.amountFilter.amountFrom = data.amountFrom;
        }

        if (data.amountTo) {
            result.amountFilter.amountTo = data.amountTo;
        }
    }

    if (data.finishedAtFrom || data.finishedAtTo) {
        if (!result.finishedDateFilter) {
            result.finishedDateFilter = {
                finishedAtFrom: '',
                finishedAtTo: '',
            };
        }

        if (data.finishedAtFrom) {
            result.finishedDateFilter.finishedAtFrom = dateHelpers.formatTo(
                data.finishedAtFrom,
                INVOICES_CONSTANTS.FILTER_DATE_FORMAT,
                'UTC',
            );
        }

        if (data.finishedAtTo) {
            result.finishedDateFilter.finishedAtTo = dateHelpers.formatTo(
                data.finishedAtTo,
                INVOICES_CONSTANTS.FILTER_DATE_FORMAT,
                'UTC',
            );
        }
    }

    return result;
};

export const filtersHelpers = { getFiltersData };
