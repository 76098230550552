import type { IPaymentReport } from 'api/invoices/payment/types';

import type { TTableColumn } from 'models/Table';

import { ActionsCell } from './components/ActionsCell';
import { DateCell } from './components/DateCell';
import { EnabledCell } from './components/EnabledCell';
import { FiltersCell } from './components/FiltersCell';
import { ReportTypeCell } from './components/ReportTypeCell';
import { SchedulingTypeCell } from './components/SchedulingTypeCell';

export const getColumns = (
    onToggleEnabled: (identity: string, checked: boolean) => void,
    onDeleteReport: (identity: string) => void,
): TTableColumn<IPaymentReport>[] => [
    {
        title: 'invoices.payment.editExportSettingsTable.email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'invoices.payment.editExportSettingsTable.date',
        key: 'date',
        render: (_, record) => <DateCell scheduling={record.scheduling} />,
    },
    {
        title: 'invoices.payment.editExportSettingsTable.periodicity',
        dataIndex: 'scheduling',
        key: 'scheduling',
        render: (_, record) => (
            <SchedulingTypeCell scheduling={record.scheduling} />
        ),
    },
    {
        width: 200,
        title: 'invoices.payment.editExportSettingsTable.filters',
        dataIndex: 'filters',
        key: 'filters',
        render: (filters) => <FiltersCell filters={filters} />,
    },
    {
        title: 'invoices.payment.editExportSettingsTable.type',
        dataIndex: ['type'],
        key: 'type',
        align: 'center',
        render: (_, record) => <ReportTypeCell type={record.type} />,
    },
    {
        title: 'invoices.payment.editExportSettingsTable.enabled',
        dataIndex: ['scheduling', 'enabled'],
        key: 'enabled',
        align: 'center',
        render: (_, record) => (
            <EnabledCell
                identity={record.identity}
                scheduling={record.scheduling}
                onToggleEnabled={onToggleEnabled}
            />
        ),
    },
    {
        title: 'invoices.payment.editExportSettingsTable.actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
            <ActionsCell
                identity={record.identity}
                onDeleteReport={onDeleteReport}
                scheduling={record.scheduling}
            />
        ),
    },
];
