import { notification, Switch } from 'antd';
import type { IPayoutReport } from 'api/invoices/payout/types';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

interface IEnabledCellProps {
    identity: string;
    scheduling: IPayoutReport['scheduling'];
    onToggleEnabled: (identity: string, checked: boolean) => void;
}

export const EnabledCell: TComponent<IEnabledCellProps> = (props) => {
    const { identity, onToggleEnabled, scheduling } = props;

    const { t } = useTranslation();

    if (!scheduling) {
        return <>-</>;
    }

    const isEnabled = scheduling.enabled;

    const handleToggleEnabled = (checked: boolean) => {
        try {
            onToggleEnabled(identity, checked);
        } catch {
            notification.error({
                message: t(
                    'invoices.payout.editExportSettingsTable.hasActiveLimitExceeded',
                ),
            });
        }
    };

    return <Switch checked={isEnabled} onChange={handleToggleEnabled} />;
};
