import type { TExportSettingsScheduling } from 'api/invoices/payment/types';
import { useTranslation } from 'react-i18next';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import type { TComponent } from 'utils/types/component';

import { editSettingsTableHelpers } from '../../helpers';

interface IDateCellProps {
    scheduling: TExportSettingsScheduling | undefined;
}

export const DateCell: TComponent<IDateCellProps> = (props) => {
    const { scheduling } = props;

    const { t } = useTranslation();

    if (!scheduling) {
        return null;
    }

    if ('executionTime' in scheduling && scheduling.type === 'daily') {
        const time = dateHelpers.formatTimeWithoutSeconds(
            scheduling.executionTime,
        );
        return <span>{time}</span>;
    }

    if ('weekDays' in scheduling) {
        const { weekDays } = scheduling;

        if (!weekDays || !weekDays.length) {
            return null;
        }

        const dayTimePairs = weekDays.map((wd) => {
            const dayName = editSettingsTableHelpers.mapDayOfWeek(
                wd.dayOfWeek,
                t,
            );
            const time = dateHelpers.formatTimeWithoutSeconds(wd.executionTime);
            return `${dayName} ${time}`;
        });

        return <span>{dayTimePairs.join('-')}</span>;
    }

    if ('monthDays' in scheduling) {
        const { monthDays } = scheduling;

        if (!monthDays || !monthDays.length) {
            return null;
        }

        const dayTimePairs = monthDays.map((md) => {
            const time = dateHelpers.formatTimeWithoutSeconds(md.executionTime);
            return `${md.dayNumber} ${time}`;
        });

        return <span>{dayTimePairs.join('-')}</span>;
    }

    return null;
};
