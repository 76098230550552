import { Modal, Tabs } from 'antd';
import type {
    TBalanceDetailResponse,
    TPaymentPaymentMethod,
    TPayoutPaymentMethod,
} from 'api/merchants/balance/types';
import { useTranslation } from 'react-i18next';
import { balanceTableFiltersSelectors } from 'store/merchants/balanceTableFiltersSelectors';
import { balanceTableSelectors } from 'store/merchants/balanceTableSelectors';
import { useBalanceTable } from 'store/merchants/useBalanceTable';
import { useBalanceTableFilters } from 'store/merchants/useBalanceTableFilters';
import Table from 'ui/Table';
import { getSortParams } from 'utils/helpers/tableHelpers';
import type { TNullable } from 'utils/types/common';
import type { TComponent } from 'utils/types/component';

import type { TBalance } from 'models/Merchant/Balance';
import type { TTableSort } from 'models/Table';

import styles from './BalanceTable.module.scss';
import { columns } from './columns';

const { TabPane } = Tabs;

type DetailsModalProps = {
    isModalVisible: boolean;
    currentBalance: TBalance;
    onCloseModal: () => void;
    balanceDetails: TNullable<TBalanceDetailResponse>;
};

const MODAL_WIDTH = 920;

const DetailsModal: TComponent<DetailsModalProps> = ({
    isModalVisible,
    onCloseModal,
    balanceDetails,
    currentBalance,
}) => {
    const { t } = useTranslation();

    const body = useBalanceTableFilters(balanceTableFiltersSelectors.getBody);
    const detailsParams = useBalanceTableFilters(
        balanceTableFiltersSelectors.getDetailsParams,
    );
    const getBalanceDetail = useBalanceTable(
        balanceTableSelectors.getBalanceDetail,
    );

    const handleSortTable = (
        _: number,
        sortParams: TTableSort<TPayoutPaymentMethod | TPaymentPaymentMethod>,
    ) => {
        const { property, order } = getSortParams(sortParams);

        const newParams = { ...detailsParams };

        if (property && order) {
            newParams.sort = `${property},${order}`;
        } else {
            newParams.sort = '';
        }

        getBalanceDetail(
            {
                ...body,
                currencyCode: currentBalance.currencyCode,
            },
            newParams,
        );
    };

    return (
        <Modal
            title={
                currentBalance.merchantName || t('balance.detailsModal.title')
            }
            open={isModalVisible}
            onCancel={onCloseModal}
            footer={null}
            width={MODAL_WIDTH}
        >
            {balanceDetails && (
                <Tabs>
                    <TabPane
                        tab={t('balance.detailsModal.refillMethod')}
                        key='1'
                    >
                        <div className={styles.detailTable}>
                            <Table
                                tableRows={balanceDetails.paymentMethods}
                                columns={columns.depositColumns}
                                pagination={false}
                                onChange={handleSortTable}
                            />
                        </div>
                    </TabPane>
                    <TabPane
                        tab={t('balance.detailsModal.payoutMethod')}
                        key='2'
                    >
                        <div className={styles.detailTable}>
                            <Table
                                tableRows={balanceDetails.payoutMethods}
                                columns={columns.payoutsColumns}
                                pagination={false}
                                onChange={handleSortTable}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            )}
        </Modal>
    );
};

export default DetailsModal;
